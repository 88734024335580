import React from "react";
import Brand from "./shopBy/Brand";
import Category from "./shopBy/Category";
import Color from "./shopBy/Color";
import Price from "./shopBy/Price";
import Size from "./shopBy/Size";
import Breadcrumbs from '../Breadcrumbs1';

const ShopSideNav = ({onCategoryChange,onColorChange},selectedColor) => {
  return (
    <div className="w-full flex flex-col space-y-2"> {/* Adds consistent spacing between components */}
      <Breadcrumbs title={"Products"} />
      <Category icons={false} onCategoryChange={onCategoryChange}/>
      <Color onColorSelect={onColorChange} selectedColor={selectedColor}/>
      {/* <Brand /> */}
      <Price />
      <Size />
    </div>
  );
};

export default ShopSideNav;
