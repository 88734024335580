import React, { useState } from 'react';
import upload from '../assets/upload.png';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import app from '../firebaseConfig.js';
import { getStorage } from "firebase/storage";
import { getDatabase, ref as databaseRef, set, push } from "firebase/database";

const Add = () => {
  const [images, setImages] = useState([null, null, null, null]);
  const [imageUrls, setImageUrls] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [type, setType] = useState("");
  const [thought, setThoughts] = useState("");

  const [stock, setStocks] = useState("");
  const [bestseller, setBestseller] = useState(false);
  const [sizes, setSizes] = useState([]);

  const [isLoading, setIsLoading] = useState(false); // Loading state
  const storage = getStorage();
  const database = getDatabase(app);

  // File upload logic with Firebase
  const uploadImage = async (key) => {
    const urls = await Promise.all(
      images.map(async (file, index) => {
        if (file) {
          // Generate a unique key for each image
  
          // Set the filename as key_index format
          const fileName = `${key}_${index}.jpeg`;
          const storageRef = ref(storage, `Collection/${fileName}`);
          const metaData = { contentType: 'image/jpeg' };
  
          await uploadBytes(storageRef, file, metaData);
          const url = await getDownloadURL(storageRef);
  
          return { key: key, url }; // Return both key and URL
        }
        return null;
      })
    );
    return urls.filter(item => item !== null); // Return only valid URLs with keys
  };
  
  // Handler for submitting product details
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
  
    try {
  
      // Use Firebase's push() to generate a unique product key
      const productRef = push(databaseRef(database, `Products/${type}`));
      const productId = productRef.key;
      const uploadedImages = await uploadImage(productId);

      const uploadedImagesWithIndex = uploadedImages.map((image, idx) => ({
        ...image,
        index: idx
      }));
      
      // Format image data with key and URL
      const imagesData = uploadedImagesWithIndex.reduce((acc, image) => {
        acc[image.index] = image.url;
        return acc;
      }, {});
  
      const productData = {
        id: productId,
        name,
        description,
        price,
        color,
        stock,
        bestseller,
        sizes,
        type,
        thought,
        images: imagesData, // Save images with key and URL in the product data
      };
  
      // Save product data under the generated productId
      await set(productRef, productData);
  
      alert("Product data added successfully");
  
      // Clear form after submission
      setName("");
      setDescription("");
      setPrice("");
      setStocks("");
      setColor("");
      setBestseller(false);
      setSizes([]);
      setType("")
      setImages([null, null, null, null]);
      setImageUrls([]);
    } catch (error) {
      alert("Data submission error: " + error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Handle size selection toggle
  const toggleSize = (size) => {
    setSizes((prev) => 
      prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
    );
  };

  return (
    <form onSubmit={onSubmitHandler} className='flex flex-col w-full items-start gap-3'>
      {/* Upload Images */}
      <div>
        <p className='mb-2'>Upload Images</p>
        <div className='flex gap-3'>
          {images.map((img, index) => (
            <label key={index} htmlFor={`image${index}`}>
              <img 
                className='w-20' 
                src={!img ? upload : URL.createObjectURL(img)} 
                alt={`image${index + 1}`} 
              />
              <input 
                type="file" 
                id={`image${index}`} 
                hidden 
                onChange={(e) => {
                  const file = e.target.files[0];
                  setImages((prev) => {
                    const updatedImages = [...prev];
                    updatedImages[index] = file;
                    return updatedImages;
                  });
                }}
              />
            </label>
          ))}
        </div>
      </div>
      
      
      <div className='flex flex-col sm:flex-row gap-2 w-full sm:gap-8'>
        <div>
          <p className='mb-2'>T Shirt Type</p>
          <select 
            onChange={(e) => setType(e.target.value)} 
            className='w-full px-3 py-2'
            required
          >
           <option >Select T-Shirt Type</option>
            <option value="OverSized">OverSized</option>
            <option value="Regular">Regular</option>
            <option value="Essentials">Essentials</option>
          </select>
        </div>
        </div>

      {/* Product Details */}
      <div className='w-full'>
        <p className='mb-2'>Product Name</p>
        <input 
          onChange={(e) => setName(e.target.value)} 
          value={name} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          type="text" 
          placeholder='Type here' 
          required 
        />
      </div>

      <div className='w-full'>
        <p className='mb-2'>Product Description</p>
        <textarea 
          onChange={(e) => setDescription(e.target.value)} 
          value={description} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>
      
      <div className='w-full'>
        <p className='mb-2'>Artist Thoughts</p>
        <textarea 
          onChange={(e) => setThoughts(e.target.value)} 
          value={thought} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>

      <div className='flex flex-col sm:flex-row gap-2 w-full sm:gap-8'>
        <div>
          <p className='mb-2'>Color</p>
          <select 
            onChange={(e) => setColor(e.target.value)} 
            className='w-full px-3 py-2'
            required
          >
            <option>Select Color</option>
            <option value="Cosmic White">Cosmic White</option>
            <option value="Jet Black">Jet Black</option>
            <option value="Midnight Blue">Midnight Blue</option>
          </select>
        </div>

        <div>
          <p className='mb-2'>Quantity</p>
          <input 
            onChange={(e) => setStocks(e.target.value)} 
            value={stock} 
            className='w-full px-3 py-2 sm:w-[120px]' 
            type="number" 
            placeholder='Stocks' 
            required 
          />
        </div>

        <div>
          <p className='mb-2'>Product Price</p>
          <input 
            onChange={(e) => setPrice(e.target.value)} 
            value={price} 
            className='w-full px-3 py-2 sm:w-[120px]' 
            type="number" 
            placeholder='Price' 
            required 
          />
        </div>
      </div>

      <div>
        <p className='mb-2'>Product Sizes</p>
        <div className='flex gap-3'>
          {["S", "M", "L", "XL", "XXL"].map((size) => (
            <div key={size} onClick={() => toggleSize(size)}>
              <p className={`${sizes.includes(size) ? "bg-pink-200" : "bg-slate-200"} cursor-pointer px-3 py-1`}>
                {size}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className='flex gap-2 mt-2'>
        <input 
          type="checkbox" 
          id='bestseller' 
          checked={bestseller} 
          onChange={() => setBestseller((prev) => !prev)} 
        />
        <label className='cursor-pointer' htmlFor="bestseller">Add to Bestseller</label>
      </div>

      <button type='submit' className='w-28 py-3 mt-4 bg-black text-white' disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Add'}
      </button>
    </form>
  );
};

export default Add;
