import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import SideShopNav1 from "./ShopSideNav"; // Make sure the import path is correct
import { getDatabase, get, ref as databaseRef } from "firebase/database";
import ShopSideNav from "./ShopSideNav";

const Pagination = ({ itemsPerPage }) => {
  const [products, setProducts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedColor, setSelectedColor] = useState(""); // Add selectedColor state

  useEffect(() => {
    const database = getDatabase();

    const fetchData = async (path) => {
      const ref = databaseRef(database, path);
      const snapshot = await get(ref);
      if (snapshot.exists()) {
        const data = snapshot.val();
        return Object.keys(data)
          .map((key) => {
            const productData = data[key];
            if (!productData || !productData.name || !productData.id) {
              return null;
            }
            const images = productData.images ? Object.values(productData.images) : [];
            return {
              key_id: productData.id,
              productName: productData.name,
              price: productData.price,
              stocks: productData.stock,
              color: productData.color,
              badge: productData.badge,
              des: productData.des,
              img: images[0] || "",
              allImages: images,
              isLiked: false,
            };
          })
          .filter(Boolean)
          .filter(
            (product) =>
              (!selectedColor || product.color === selectedColor) // Filter by color if selectedColor is set
          );
      }
      return [];
    };

    const fetchAllData = async () => {
      if (selectedCategory) {
        const categoryProducts = await fetchData(`Products/${selectedCategory}`);
        setProducts(categoryProducts);
      } else {
        const overSizedProducts = await fetchData("Products/OverSized");
        const essentialProducts = await fetchData("Products/Essentials");
        const regularProducts = await fetchData("Products/Regular");

        setProducts([
          ...overSizedProducts,
          ...essentialProducts,
          ...regularProducts,
        ]);
      }
    };

    fetchAllData();

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [selectedCategory, selectedColor]); // Add selectedColor to dependencies
  
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setItemOffset(0);
  };

  // Handle color change
  const handleColorChange = (colorTitle) => {
    setSelectedColor(colorTitle);
    setItemOffset(0); // Reset pagination when color changes
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
    setItemStart(newOffset + 1);
  };

  const handleLikeToggle = (productId) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, isLiked: !product.isLiked } : product
      )
    );
  };

  return (
    <div className="mt-[1%] flex">
      {/* Sidebar for both views */}
      {isMobile ? (
        <>
          {isSidebarOpen && (
            <div
              onClick={() => setIsSidebarOpen(false)}
              className="fixed inset-0 bg-black opacity-50 z-30"
            ></div>
          )}

          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="p-2 bg-gray-200 text-gray-700 rounded-md mb-4 lg:hidden fixed top-4 left-4 z-50"
          >
            {isSidebarOpen ? "Close" : "Filter"}
          </button>

          <div
            className={`fixed inset-y-0 left-0 w-2/3 pl-[20px] bg-white shadow-lg z-50 transition-transform duration-300 transform ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } lg:hidden`}
          >
            <SideShopNav1
              onCategoryChange={(category) => {
                handleCategoryChange(category);
                setIsSidebarOpen(false);
              }}
              onColorChange={handleColorChange} // Pass color change handler
              selectedColor={selectedColor}
            />

          </div>
        </>
      ) : (
        <div className="w-[25%] lgl:w-[25%] hidden mdl:inline-flex flex-col h-full">

          <ShopSideNav
            onCategoryChange={handleCategoryChange}
            onColorChange={handleColorChange} // Pass color change handler
          />
          
        </div>
        
      )}
      

      {/* Product grid */}
      <div className="flex-grow ml-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {currentItems.map((item) => (
            <div key={item.id} className="w-full">
              <Product
                id={item.key_id}
                img={item.img}
                productName={item.productName}
                price={item.price}
                quantity={item.stocks}
                color={item.color}
                badge={true}
                des={item.des}
                isLiked={item.isLiked}
                onLikeToggle={() => handleLikeToggle(item.id)}
              />
            </div>
          ))}
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-4">
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
            pageClassName="mr-6"
            containerClassName="flex text-base font-semibold font-titleFont py-10"
            activeClassName="bg-black text-white"
            nextLabel={null}
            previousLabel={null}
          />

          <p className="text-base font-normal text-lightText">
            Products from {itemStart} to {Math.min(endOffset, products.length)} of {products.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
