import React from 'react'
import { Link } from "react-router-dom";

function ViewMore() {
  return (
    <div className='w-full text-center'>
      <Link to="/shop ">
      <button className="bg-transparent mb-10 text-black font-bodyFont w-[150px] h-[40px] text-center border border-black hover:bg-black hover:text-white duration-300 font-bold">
  View More
</button>


    </Link>
  </div>
  )
}

export default ViewMore
