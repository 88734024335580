import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart, toggleWishlist } from "../../redux/orebiSlice";
import SizeCircles from "./Size";
import StarRating from "./StarRating";


import { BsStar, BsStarFill, BsCheckCircle } from 'react-icons/bs';

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);
  const [userRating, setUserRating] = useState(0);
  const [isLiked, setIsLiked] = useState(
    wishlistItems.some((item) => item._id === productInfo?.id)
  );
  const [addedToCart, setAddedToCart] = useState(false);
  const [selectedColor, setSelectedColor] = useState(productInfo?.color?.[0]);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [pincode, setPincode] = useState("");
  const [deliveryMessage, setDeliveryMessage] = useState(null);

  if (!productInfo) return <div>Loading...</div>;

  const handleRatingSelect = (rating) => {
    setUserRating(rating);
    // Here, you would normally send the rating to the backend or Redux store to save
    console.log(`User rating submitted: ${rating}`);
  };

  const handleAddToCart = () => {
    const itemToAdd = {
      id: productInfo.id,
      productName: productInfo.name,
      price: productInfo.price,
      img: productInfo.images[0],
      quantity: 1,
      color: selectedColor,
      stocks: productInfo.stock
    };
    setAddedToCart(true);
    dispatch(addToCart(itemToAdd));
  };

  const handleGoToCart = () => {
    navigate("/cart");
  };

  const handleHeartClick = () => {
    setIsLiked(!isLiked);
    dispatch(
      toggleWishlist({
        id: productInfo?.id,
        productName: productInfo?.name,
        img: productInfo?.images[0],
        price: productInfo?.price,
        color: productInfo?.color,
        isLiked: !isLiked,
      })
    );
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const toggleSizeChart = () => {
    setShowSizeChart(!showSizeChart);
  };

  const handlePincodeCheck = () => {
    const availablePincodes = ["110001", "110002", "110003"];
    setDeliveryMessage(
      availablePincodes.includes(pincode)
        ? "Delivery is available to this pincode."
        : "Sorry, delivery is not available to this pincode."
    );
  };

  return (
    <div className="p-4 sm:p-8 pt-8 ">
      <div className="flex flex-col gap-4">
        <h2 className="text-4xl font-semibold">
          {productInfo.name}
          <br />
          <p className="text-black text-sm">Unisex T-shirt.</p>
        </h2>
        <p className="text-2xl font-semibold text-green-600">
        ₹{productInfo.price}
          <br />
          <p className="text-black text-sm">MRP incl. of all taxes.</p>
        </p>

        {/* Colors Section */}
        <p className="font-medium text-lg">
          <span className="font-normal">Available Colors:</span>
        </p>
        <div className="flex gap-2">
          {['#0d1038', '#e4e5ea', '#121013'].map((color, index) => (
            <div
              key={index}
              className={`w-8 h-8 rounded-full cursor-pointer border ${selectedColor === color ? 'border-black' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => handleColorClick(color)}
            />
          ))}
        </div>

        {/* Size Selection */}
        <div className='mt-2'>
          <h3>Select Size:</h3>
          <SizeCircles />
          <button
            onClick={toggleSizeChart}
            className=" text-sm underline text-black-800 hover:text-black mt-4"
          >
            View Size Chart
          </button>
        </div>

        {/* Size Chart Modal */}
        {showSizeChart && (
  <div className="relative mt-4 p-4 bg-white border rounded-md shadow-md w-80">
    <h2 className="text-lg font-semibold mb-4">Size Chart</h2>
    <table className="w-full text-left">
      <thead>
        <tr>
          <th className="border-b-2 p-2">Size</th>
          <th className="border-b-2 p-2">Chest (inches)</th>
          <th className="border-b-2 p-2">Waist (inches)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-2">S</td>
          <td className="p-2">34-36</td>
          <td className="p-2">28-30</td>
        </tr>
        <tr>
          <td className="p-2">M</td>
          <td className="p-2">38-40</td>
          <td className="p-2">32-34</td>
        </tr>
        <tr>
          <td className="p-2">L</td>
          <td className="p-2">42-44</td>
          <td className="p-2">36-38</td>
        </tr>
        <tr>
          <td className="p-2">XL</td>
          <td className="p-2">46-48</td>
          <td className="p-2">40-42</td>
        </tr>
      </tbody>
    </table>
    <button
      onClick={toggleSizeChart}
      className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
    >
      Close
    </button>
  </div>
)}

        {/* Buttons */}
<div className="button-container flex gap-4 mt-4 ">
  <button
    onClick={addedToCart ? handleGoToCart : handleAddToCart}
    className={`flex items-center justify-center w-[200px] h-[40px] ${
      addedToCart ? 'bg-green-500 hover:bg-green-600 px-3 md:px-0' : 'bg-gray-800 hover:bg-black'
    } duration-300 text-white text-lg font-titleFont whitespace-nowrap px-3 md:px-0`}
  >
    {addedToCart ? (
      <>
        <BsCheckCircle className="mr-2" /> Go to Cart
      </>
    ) : (
      "Add to Cart"
    )}
  </button>

  <button
    onClick={handleHeartClick}
    className={`flex items-center justify-center w-[150px] h-[40px] border ${
      isLiked ? "bg-black" : "bg-gray-800"
    } hover:bg-black text-white duration-300 whitespace-nowrap px-3 md:px-0`}
  >
    {isLiked ? (
      <BsStarFill className="text-xl text-red-500 mr-2" />
    ) : (
      <BsStar className="text-xl mr-2" />
    )}
    Wishlist
  </button>
</div>

        {/* Star Rating */}
        <div className="flex items-center mt-4">
          <StarRating
            rating={productInfo.averageRating}
            onRate={handleRatingSelect}
            size={25} // Increase star size for better visibility
          />
          <span className="text-l ml-2">{productInfo.averageRating} out of 5</span>
        </div>
        <div>
          <span className="text-l">Your Rating: {userRating} out of 5</span>
        </div>

        {/* Delivery Pincode Check */}
        <div className="flex flex-col mt-4">
          <h3 className="font-medium text-lg">Check Delivery Pincode</h3>
          <div className="flex items-center mt-2">
            <input
              type="text"
              placeholder="Enter pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="border p-2 rounded-l-md w-40"
            />
            <button
              onClick={handlePincodeCheck}
              className="bg-gray-700 text-white p-2 rounded-r-md hover:bg-black"
            >
              Check
            </button>
          </div>
          {deliveryMessage && <p className="mt-2 text-sm">{deliveryMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
