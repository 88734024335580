import React, { useEffect, useState } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleWishlist, addToCart } from "../../../orebiSlice2";
import Badge from "./Badge";
import { deleteItem } from "../../../orebiSlice2";
import SkeletonLoader from './SkeletonLoader'

const Product = (props) => {
  const { isWishlistPage } = props; 
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.orebiReducer.wishlist);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time for demonstration (replace with real loading if needed)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust time as per requirement
  
    return () => clearTimeout(timer);
  }, []);
  // Setting default state for wishlist star based on wishlist items
  const [isLiked, setIsLiked] = useState(
    wishlistItems.some((item) => item.id === props.id)
  );

  useEffect(() => {
    const likedStatus = wishlistItems.some((item) => item.id === props.id);
    setIsLiked(likedStatus);
  }, [wishlistItems, props.id]);
  

  const handleHeartClick = () => {
    setIsLiked(!isLiked);
    dispatch(
      toggleWishlist({
        id: props.id,
        productName: props.productName,
        img: props.img,
        price: props.price,
        color: props.color,
        stocks: props.quantity,
        isLiked: !isLiked,
      })
    );
  };

  const handleAddToCart = () => {
    const itemToAdd = {
      // Use a unique identifier (e.g., cartItemId) for each cart item
      id: props.id,
      productName: props.productName,
      price: props.price,
      img: props.img,
      quantity: 1,
      color: props.color,
      stocks: props.stock,
    };
    // alert(itemToAdd.id)

    try {
      dispatch(addToCart(itemToAdd));
      dispatch(deleteItem); // Make sure deleteItem is a function if you want to use it here
    } catch (error) {
      console.error("Failed to add item to cart:", error);
      alert(`Failed to add item to cart. Check the console for details.${error}`);
    }
  };

  const navigate = useNavigate();
  const handleProductDetails = () => {
    localStorage.setItem("productKey", props.id);
    navigate(`/product/${props.id}`, {
      state: { item: props.id },
    });
  };

  return (
    <div className="w-full relative group border border-l hover:shadow-lg hover:shadow-black transition-shadow duration-300">
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <div
            className="max-w-80 max-h-70 relative overflow-hidden"
            onClick={handleProductDetails}
          >
            <div className="transition-transform duration-500 hover:scale-[1.8] md:hover:scale-[1.8] lg:hover:scale-[1.8] hover:-translate-y-6 md:hover:translate-y-0 lg:hover:-translate-y-2">
              <img
                className="w-full h-full object-cover md:object-contain lg:object-cover bg-gray-100"
                src={props.img}
                alt={props.productName}
              />
            </div>
          </div>
          
          <div className="absolute top-1 left-3">
            {props.badge && <Badge className="blinking-badge" text="New" />}
          </div>
  
          <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
            <div className="flex items-center justify-between font-titleFont">
              <h2 className="text-lg text-primeColor font-bold">{props.productName}</h2>
              <p className="text-[#767676] text-[14px]">₹{props.price}</p>
            </div>
  
            <div className="w-full mt-1">
              <p className="text-[#767676] blinking-badge_stock text-[14px]">
                Only {props.quantity} left!
              </p>
            </div>
  
            <div className="flex items-center justify-between mt-1">
              <p className="text-[#767676] text-[14px]">{props.color}</p>
              {isLiked ? (
                <BsStarFill
                  className="text-xl ml-2 cursor-pointer text-[#ff1b0a]"
                  onClick={handleHeartClick}
                />
              ) : (
                <BsStar
                  className="text-xl ml-2 cursor-pointer text-gray-500"
                  onClick={handleHeartClick}
                />
              )}
            </div>
  
            {isWishlistPage && (
              <button
                onClick={handleAddToCart}
                aria-label={`Add ${props.productName} to cart`}
                className="mt-4 bg-black text-white py-2 px-4"
              >
                Add to cart
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}  

export default Product;
