import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import app from '../../firebaseConfig';
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "./ProductInfo";
import ProductDescription from "./ProductDescription";
import ProductReviews from "./ProductReviews";
import Productviewd from "./Productviewd";
import Productviewm from "./Productviewm";
import NewArrival from '../../components/home/NewArrivals/NewArrivals';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { getDatabase, ref as databaseRef, get } from "firebase/database";

const ProductDetails = () => {
  const location = useLocation();
  const [productInfo, setProductInfo] = useState({});
  const [openSection, setOpenSection] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      const productId = localStorage.getItem('productKey');
      if (!productId) return;
  
      const database = getDatabase(app);
  
      // List of paths to search for the product
      const paths = [
        `Products/OverSized/${productId}`,
        `Products/Essentials/${productId}`,
        `Products/Regular/${productId}`,
        `Essentials/${productId}`,
        `OverSized/${productId}`
      ];
  
      for (const path of paths) {
        const productRef = databaseRef(database, path);
        const snapshot = await get(productRef);
  
        if (snapshot.exists()) {
          setProductInfo(snapshot.val());
          return;
        }
      }
  
      // Alert if no product found in any path
      alert("No such product!");
    };
  
    fetchProduct();
  }, [location.state]);
  

  const handleSectionClick = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="w-full mx-auto">
      <div className="max-w-container mx-auto px-4">
        
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 h-full mt-5 pb-10 bg-gray-100 p-4">
          <div className="h-full">
            {isMobile ? (
              <Productviewm onProductSelect={setProductInfo} productInfo={productInfo} />
            ) : (
              <Productviewd onProductSelect={setProductInfo} productInfo={productInfo} />
            )}
          </div>

          <div className="h-full flex flex-col gap-6">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>

        <div className="container mx-auto mt-4 p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="p-4 rounded-lg">
              <div className="mt-2 border border-black p-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => handleSectionClick('details')}
                >
                  <h3 className="font-medium text-lg">Product Details</h3>
                  {openSection === 'details' ? <BsChevronUp /> : <BsChevronDown />}
                </div>
                {openSection === 'details' && (
                  <p className="mt-2 text-gray-700">
                     Material & Care:<br /> 240 GSM T-Shirt<br /> 
                                        International brand<br />
                    100 % cotton <br /><br />

                    Manufactured & Sold By:<br /> Pawar Celeb Fashion LLP
                  </p>
                )}
              </div>

              <div className="mt-4 border border-black p-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => handleSectionClick('description')}
                >
                  <h3 className="font-medium text-lg">Product Description</h3>
                  {openSection === 'description' ? <BsChevronUp /> : <BsChevronDown />}
                </div>
                {openSection === 'description' && (
                  <p className="mt-2 text-gray-700">
                    {productInfo.description || "Start your style journey with our high-quality printed shirts, crafted for comfort and durability. Explore 36 anime-inspired designs with bold graphics, abstract patterns, and quirky motifs - perfect for any taste and occasion."} 
                  </p>
                )}
              </div>

              <div className="mt-4 border border-black p-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => handleSectionClick('thoughts')}
                >
                  <h3 className="font-medium text-lg">Artist Thoughts</h3>
                  {openSection === 'thoughts' ? <BsChevronUp /> : <BsChevronDown />}
                </div>
                {openSection === 'thoughts' && (
                  <p className="mt-2 text-gray-700">
                    {productInfo.thought || "Celeb is A Brand where the artist showcases his thoughts in a way that matches the current trends of the generation like Anime! Celeb gives you awesome Tees and oversized tees that are based on the most popular Animes, which you can wear whenever and wherever & flaunt your style."} 
                  </p>
                )}
              </div>
            </div>

            <div className="pl-4 rounded-lg bg-light-100">
              <ProductDescription />
              <ProductReviews />
            </div>
          </div>
        </div>
        
      </div>
      <NewArrival title={"Similar Products"} />
    </div>
  );
};

export default ProductDetails;
