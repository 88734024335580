import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import back1 from "../../assets/banners/Welcome Page/welcome_Phone.png";
import backMobile from "../../assets/banners/Welcome Page/OverSized.png"; // Mobile image

function New({ onClose }) {
    const [email, setEmail] = useState("");
    const InviteCodes = ['RAYHAN', 'RAHUL', 'SWAPNIL'];
    const [password, setPassword] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const navigate = useNavigate(); // To handle redirection

    // Use custom media query hook for responsive background image
    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);
            const documentChangeHandler = () => setMatches(mediaQueryList.matches);

            mediaQueryList.addEventListener('change', documentChangeHandler);
            setMatches(mediaQueryList.matches);

            return () => mediaQueryList.removeEventListener('change', documentChangeHandler);
        }, [query]);

        return matches;
    };
    const isMobile = useMediaQuery('(max-width: 768px)'); // Adjust breakpoint as needed


    const handleEmail = (e) => {
        setEmail(e.target.value);
        setErrEmail("");
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
        setErrPassword("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!InviteCodes.includes(password.toUpperCase())) {
            alert("Invalid Invite Code. Please try again.");
        } else {
            alert("Success");
            setSuccessMsg("Welcome! Redirecting...");
            setTimeout(() => navigate('/home'), 2000); // Redirect after success
        }
    };



    return (
        <div className="flex flex-col bg-[#F1F2F2] md:flex-row w-full overflow-hidden h-screen items-center justify-center relative">

            {/* Close Button */}
            <button onClick={onClose} className="absolute top-12 right-10 text-xl font-bold text-gray-500 hover:text-red-700">✖</button>

            {/* Background Banner */}
            <div
                className={`w-full h-full bg-contain flex-shrink-0 ${isMobile ? '' : 'ml-8'}`}
                style={{
                    backgroundImage: `url(${isMobile ? backMobile : back1})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `${isMobile ? 'center' : 'center'}`,
                    backgroundSize:`${isMobile ? '' : 'cover'}`
                }}
            ></div>


            {/* Form Section */}
            <div className="w-full md:w-1/2 flex items-center justify-center p-4">
                <form onSubmit={handleSubmit} style={{ width: isMobile ? '350px' : '350px', bottom: isMobile ? '5vh' : '' }} className="w-full md:w-[350px] absolute bottom-12 lgl:bottom-12  left-1/2 transform -translate-x-1/2 flex flex-col ">
                    <div className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row gap-3">
                                <input
                                    onChange={handleEmail}
                                    value={email}
                                    className="w-full h-10 px-4 text-base border border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Enter Your Name"
                                />
                                {errEmail && <p className="text-red-500 text-sm">{errEmail}</p>}

                                <input
                                    onChange={handlePassword}
                                    value={password}
                                    className="w-full h-10 px-4 text-base border border-gray-400 outline-none"
                                    type="text"
                                    placeholder="Enter Invite Code"
                                />
                                {errPassword && <p className="text-red-500 text-sm">{errPassword}</p>}
                            </div>

                            <button
                                type="submit"
                                className="bg-white border border-gray-800 hover:text-white mt-3 w-1/2 h-8 text-black font-medium hover:bg-black transition duration-300 mx-auto">
                                Shop Celeb
                            </button>

                            <Link to='/home'>
                                <p
                                    onClick={onClose}
                                    className="flex items-center text-black justify-center text-white rounded-md">
                                    Don’t have Invite Code? Click here
                                </p>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default New;
