import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleWishlist, addToCart } from '../../redux/orebiSlice'; 
import Product from '../../components/home/Products/Product';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs1';

const Wishlist = () => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector(state => state.orebiReducer.wishlist);
  

  const handleAddToCart = (item) => {
    // Dispatch the action to add the item to the cart and remove it from the wishlist
    const itemToAdd = {
      id: item.id,
      productName: item.productName,
      price: item.price,
      img: item.img,
      quantity: 1, // Assuming 1 item to be added, you can adjust this if needed
    };
    dispatch(addToCart(itemToAdd)); // Add to cart and remove from wishlist
  };

  const idString = (name) => {
    return String(name).toLowerCase().split(" ").join("");
  };

  return (
    <div className="wishlist-container ml-4 max-w-7xl">
      <Breadcrumbs title="Wishlist" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-6">
        {wishlistItems.length > 0 ? (
          wishlistItems.map(item => (
            <div key={item._id} className="w-full">
              <Product
                item={item}
                id={item.id}
                img={item.img}
                productName={item.productName}
                price={item.price}
                color={item.color}
                badge={item.badge}
                des={item.des}
                quantity={item.stocks}
                isWishlistPage={true}
                onAddToCart={() => handleAddToCart(item)} // Handle adding to cart and remove from wishlist
              />
            </div>
          ))
        ) : (
          <p className="col-span-full ml-[2%] text-center text-lg">Your wishlist is empty!</p>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
