import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import app from "../../firebaseConfig"; // Adjust based on Firebase config file location
import { ref, onValue, getDatabase } from "firebase/database";

const Productviewm = ({ onProductSelect, productInfo }) => {
  const [images, setImages] = useState([]);
  const database = getDatabase(app);

  useEffect(() => {
    if (productInfo?.id) {
      // Construct paths for Products, Oversized, and Essentials
      const paths = [
        `Products/OverSized/${productInfo.id}/images`,
        `Products/Essentials/${productInfo.id}/images`,
        `Products/Regular/${productInfo.id}/images`,
        `Essentials/${productInfo.id}/images`,
        `OverSized/${productInfo.id}/images`
      ];
  
      // Try fetching data from each path
      paths.forEach(path => {
        const imagesRef = ref(database, path);
        
        // Fetch data from Firebase Realtime Database
        onValue(imagesRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            // Convert the data object with numeric keys to an array of image URLs
            const imagesArray = Object.keys(data).map((key) => ({
              id: key,
              img: data[key], // Accessing each image path directly
            }));
            setImages(imagesArray); // Set the images once found
          }
        });
      });
    }
  }, [productInfo?.id]);  // Run this effect when productInfo.id changes

  const handleProductClick = (product) => {
    onProductSelect(product);
  };

  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{ clickable: true }}
        navigation={false}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              className="gap-2 border-b-[1px] border-b-gray-300 cursor-pointer overflow-hidden group"
              onClick={() => handleProductClick(item)}
            >
              <img
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                src={item.img} // Using image URL from Firebase
                alt={`Product Image ${item.id}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Productviewm;
