// SampleOrderspage.js
import React from 'react';
import './OrdersPage'; // Import CSS for styling

const SampleOrderspage = ({ orders, onStatusChange }) => (
    <div className='p-4'>
        {/* Table for desktop view */}
        <div className="table-container desktop-view">
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Service Type</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.date}</td>
                            <td>{order.customer}</td>
                            <td>{order.serviceType}</td>
                            <td>{order.itemName}</td>
                            <td>{order.quantity}</td>
                            <td>${order.total.toFixed(2)}</td>
                            <td>
                                <select 
                                    value={order.status} 
                                    onChange={(e) => onStatusChange(order.id, e.target.value)}
                                >
                                    <option value="On Process">On Process</option>
                                    <option value="Done">Done</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        {/* Slider for mobile view */}
        <div className="table-container mobile-view">
            <div className="slider">
                {orders.map((order) => (
                    <div className="slider-item" key={order.id}>
                        <h4>Order ID: {order.id}</h4>
                        <p>Date: {order.date}</p>
                        <p>Customer: {order.customer}</p>
                        <p>Service Type: {order.serviceType}</p>
                        <p>Item Name: {order.itemName}</p>
                        <p>Quantity: {order.quantity}</p>
                        <p>Total: ${order.total.toFixed(2)}</p>
                        <p>Status:
                            <select 
                                value={order.status} 
                                onChange={(e) => onStatusChange(order.id, e.target.value)}
                            >
                                <option value="On Process">On Process</option>
                                <option value="Done">Done</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default SampleOrderspage;
