import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  cart: [],
  wishlist: [], // Initialize the wishlist from localStorage
};

const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    // Add individual item to the cart and remove from wishlist
    addToCart: (state, action) => {
      const itemToAdd = action.payload;

      // Check if the item already exists in the cart
      const existingCartItem = state.cart.find((item) => item.id === itemToAdd.id);

      if (existingCartItem) {
        // If the item exists, increase the quantity
        existingCartItem.quantity += 1;
      } else {
        // Otherwise, add the item to the cart with quantity 1
        state.cart.push({
          ...itemToAdd,
          quantity: 1, // You can adjust this to add the exact quantity if needed
        });
      }

      // Remove the item from the wishlist after adding it to the cart
      const wishlistIndex = state.wishlist.findIndex((item) => item._id === itemToAdd._id);
      if (wishlistIndex !== -1) {
        state.wishlist.splice(wishlistIndex, 1); // Remove from wishlist
      }

    
    },

    increaseQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item) {
        item.quantity++;
      }
    },

    decreaseQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },

    deleteItem: (state, action) => {
      const itemIdToRemove = action.payload;
      state.cart = state.cart.filter((item) => item.id !== itemIdToRemove);
    },

    resetCart: (state) => {
      state.cart = [];
    },

    toggleWishlist: (state, action) => {
      const itemIndex = state.wishlist.findIndex((item) => item.id === action.payload.id);
      if (itemIndex >= 0) {
        // If the item exists in the wishlist, remove it
        state.wishlist.splice(itemIndex, 1);
      } else {
        // If the item doesn't exist in the wishlist, add it
        state.wishlist.push(action.payload);
      }

      
    },

  },
});

export const {
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
  toggleWishlist,
} = orebiSlice.actions;

export default orebiSlice.reducer;
