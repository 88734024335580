// OrdersPage.js
import React, { useState } from 'react';

import SampleOrderspage from './SampleOrderspage';
import './OrdersPage.css';
import SummaryOrders from './SummaryOrders';

const sampleOrders = [
  {
    id: 1,
    date: "2024-11-02",
    customer: "John Doe",
    serviceType: "Standard Shipping",
    itemName: "Shoes",
    quantity: 1,
    total: 50,
    status: "Done",
  },
  {
    id: 2,
    date: "2024-11-03",
    customer: "Jane Smith",
    serviceType: "Express Delivery",
    itemName: "Jacket",
    quantity: 2,
    total: 120,
    status: "On Process",
  },
  {
    id: 3,
    date: "2024-11-03",
    customer: "Alice Brown",
    serviceType: "Standard Shipping",
    itemName: "Hat",
    quantity: 3,
    total: 75,
    status: "Done",
  },
];

const OrdersPage = () => {
  const [orders, setOrders] = useState(sampleOrders);

  // Function to handle status change
  const handleStatusChange = (id, newStatus) => {
    const updatedOrders = orders.map((order) =>
      order.id === id ? { ...order, status: newStatus } : order
    );
    setOrders(updatedOrders);
  };

  return (
    <div>
      <h2>Orders Summary</h2>
      <SummaryOrders orders={orders} />
      <h2>Orders List</h2>
      <SampleOrderspage orders={orders} onStatusChange={handleStatusChange} />
    </div>
  );
};

export default OrdersPage;
