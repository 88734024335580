import React, { useState } from "react";
import './Productinfo.css';

const SizeCircles = () => {
  const [selectedSize, setSelectedSize] = useState(null);
  const sizes = ['S', 'M', 'L'];

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className="size-container">
      {sizes.map((size, index) => (
        <div 
          key={index} 
          className={`size-circle ${selectedSize === size ? 'selected' : ''}`}
          onClick={() => handleSizeClick(size)}
        >
          {size}
        </div>
      ))}
    </div>
  );
};

export default SizeCircles;
