import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiMenu } from "react-icons/hi";
import Flex from "../../designLayouts/Flex";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdSwitchAccount } from "react-icons/md";
import { FaRegStar, FaStar } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const HeaderBottom = () => {
  const products = useSelector((state) => state.orebiReducer.cart);
  const wishlist = useSelector((state) => state.orebiReducer.wishlist);
  const [show, setShow] = useState(false);
  const [MyAccount, setMyAccount] = useState(false);

  const menuRef = useRef();
  const accountRef = useRef();
  const [userName, setUserName] = useState(null);
  const auth = getAuth();

  const Name = localStorage.getItem("UserName");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("IsLoggedIn");
    localStorage.removeItem("UserName");
    localStorage.removeItem("userKey");
    setIsLoggedIn(false);
    setShow(false);
    navigate("/signin");
  };

  useEffect(() => {
    const checkAuthStatus = () => {
      const userLoggedIn = Boolean(localStorage.getItem("IsLoggedIn"));
      setIsLoggedIn(userLoggedIn);
    };
    checkAuthStatus();
  }, []);

  const handleAccountClick = () => {
    if (isLoggedIn) {
      setMyAccount(!MyAccount);
      setShow(false);
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserName(user.displayName);
      } else {
        setUserName(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        menuRef.current && !menuRef.current.contains(e.target) &&
        accountRef.current && !accountRef.current.contains(e.target)
      ) {
        setShow(false);
        setMyAccount(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full h-16 bg-white sticky top-20 z-50 border-b-[1px] border-b-gray-200">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-row items-center justify-between w-full px-4 sm:px-6 md:px-8 lg:px-10 h-16 lg:pb-0">
          <div
            onClick={() => setShow(!show)}
            ref={menuRef}
            className="flex items-center gap-2 text-primeColor cursor-pointer"
          >
            <HiMenu className="w-8 h-8 sm:w-6 h-6 lg:h-8 w-8  xl:h-8 xl:w-8" />
            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-16 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                <Link to='/shop'>
                  <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Limited Edition
                  </li>
                </Link>
                <Link to='/about'>
                  <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    About us
                  </li>
                </Link>
                <Link to='/contact'>
                  <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Contact
                  </li>
                </Link>
              </motion.ul>
            )}
          </div>

          <div className="ml-[10%] my-[10%]">
            <h3 className="text-black text-[12px] md:text-xl flex items-center text-center ml-26 sm:text-[12px]">
              Welcome to Celeb,
              <span className="text-[12px] md:text-xl text-blue-500 ml-1 sm:text-[14px]">
                {Name ? Name.split(' ')[0] : 'Guest'}
              </span>!
              <span className="hidden md:inline ml-1"> Your style journey starts here</span>
            </h3>
          </div>

          <div className="flex items-center cursor-pointer relative gap-1 sm:gap-2 lg:gap-4">
            <div
              onClick={handleAccountClick}
              ref={accountRef}
              className="w-8 h-8 flex items-center justify-center group cursor-pointer"
            >
              <MdSwitchAccount className="text-2xl mt-0.5 group-hover:scale-110 transition-transform duration-200" />
            </div>

            {MyAccount && isLoggedIn && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-12 ml-[-40%] z-50 bg-primeColor w-[130%] text-[#767676] h-auto p-4 pb-6"
              >
                <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Hello {Name ? Name.split(" ")[0] : "Guest"}
                </li>
                <Link to="/myorders">
                  <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    My Orders
                  </li>
                </Link>
                <Link to="/contact">
                  <li className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    Contact
                  </li>
                </Link>
                <li
                  onClick={handleLogout}
                  className="text-gray-400 px-4 py-1 border-b border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                >
                  Logout
                </li>
              </motion.ul>
            )}

            <Link to="/wishlist">
              <div className="w-8 h-8 flex items-center justify-center group cursor-pointer relative">
                {wishlist.length > 0 ? (
                  <FaStar className="text-[#ff1b0a] text-2xl transition-transform duration-300" />
                ) : (
                  <FaRegStar className="text-black text-2xl transition-transform duration-300" />
                )}
                {wishlist.length > 0 && (
                  <p className="absolute top-0 right-0 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                    {wishlist.length}
                  </p>
                )}
              </div>
            </Link>

            <Link to="/cart">
              <div className="w-8 h-8 flex items-center justify-center group cursor-pointer relative">
                <RiShoppingCart2Fill className="text-2xl group-hover:scale-110 transition-transform duration-200" />
                {products.length > 0 && (
                  <p className="absolute top-0 right-0 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                    {products.length}
                  </p>
                )}
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
