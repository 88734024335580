import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import React, { useState,useEffect } from 'react';
import BannerBottom from "./components/Banner/BannerBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart_1";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import NewFooter from "./components/home/Footer/NewFooter";
import TermsAndConditions from "./components/home/Footer/TermsAndConditions";
import TermsOfUse from "./components/home/Footer/TermsOfUse";
import PrivacyNotice from "./components/home/Footer/PrivacyNotice";
import Cookie from './components/home/Footer/CookiePolicy'
import Wishlist from "./pages/Cart/Wishlist";
import Add from "./Admin/Add";
import New from './components/Splash/New'
import AdminPanel from "./Admin/AdminPanel";
import PlaceOrder from './pages/ProductDetails/PlaceOrder'
import Essentials from './Admin/Essential'
import OverSized from './Admin/OverSized'
import List from "./Admin/List";
import Orders from "./Admin/Orders/OrdersPage";
import MyOrder from "./pages/Account/MyOrders";


// Import your components here...

const Layout = () => {
  const [isSplashVisible, setIsSplashVisible] = useState(false);

  const handleCloseSplash = () => {
    setIsSplashVisible(false);
  };

  useEffect(() => {
    // Check sessionStorage to see if the banner has been shown
    const hasSeenBanner = sessionStorage.getItem("hasSeenWelcomeBanner");

    if (!hasSeenBanner) {
      // Show the banner and set the flag in sessionStorage
      setIsSplashVisible(true);
      sessionStorage.setItem("hasSeenWelcomeBanner", "true");
    }
  }, []);

  return (
    <div>
      {isSplashVisible ? (
        <New onClose={handleCloseSplash} />
      ) : (
        <>
          <BannerBottom />
          <Header />
          <HeaderBottom />
          <ScrollRestoration />
          <Outlet />
          <NewFooter />
        </>
      )}
    </div>
  );
};

// Define routes using createBrowserRouter and createRoutesFromElements
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route  element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="placeOrder" element={<PlaceOrder />} />
        <Route path="welcome" element={<New />} />
        <Route path="myorders" element={<MyOrder />} />
        <Route path="home" element={<Home />} />
        <Route path="shop" element={<Shop />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="journal" element={<Journal />} />
        <Route path="termsandconditions" element={<TermsAndConditions />} />
        <Route path="useofterms" element={<TermsOfUse />} />
        <Route path="privacynotice" element={<PrivacyNotice />} />
        <Route path="cookie" element={<Cookie />} />
        <Route path="offer" element={<Offer />} />
        <Route path="product/:id" element={<ProductDetails />} />
        <Route path="cart" element={<Cart />} />
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="paymentgateway" element={<Payment />} />
      </Route>
      <Route path="signup" element={<SignUp />} />
      <Route path="signin" element={<SignIn />} />
      {/* Admin Panel with sub-routes */}
      <Route path="admin" element={<AdminPanel />}>
        <Route path="add" index element={<Add />} /> {/* Default route for Admin Panel */}
        <Route path="list" element={<List />} />
        <Route path="orders" element={<Orders />} />
        <Route path="essentials" element={<Essentials />} />
        <Route path="oversized" element={<OverSized />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont bg-white">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;


