// Summary.js
import React from 'react';
import { FaBoxOpen, FaShippingFast, FaCheckCircle, FaDollarSign } from 'react-icons/fa';
import './OrdersPage.css';

const SummaryOrders = ({ orders = [] }) => {
  const totalOrders = orders.length;
  const ordersOnProcess = orders.filter(order => order.status === "On Process").length;
  const ordersDone = orders.filter(order => order.status === "Done").length;
  const totalIncome = orders.reduce((acc, order) => acc + order.total, 0);

  return (
    <div className="summary-grid">
      <div className="summary-item">
        <FaBoxOpen size={30} className="summary-icon" />
        <h4>Total Orders</h4>
        <p>{totalOrders}</p>
      </div>
      <div className="summary-item">
        <FaShippingFast size={30} className="summary-icon" />
        <h4>Orders On Process</h4>
        <p>{ordersOnProcess}</p>
      </div>
      <div className="summary-item">
        <FaCheckCircle size={30} className="summary-icon" />
        <h4>Orders Done</h4>
        <p>{ordersDone}</p>
      </div>
      <div className="summary-item">
        <FaDollarSign size={30} className="summary-icon" />
        <h4>Total Income</h4>
        <p>${totalIncome.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default SummaryOrders;
