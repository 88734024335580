/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState,useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL,listAll,  } from 'firebase/storage';
import app from '../firebaseConfig';
import { getStorage } from "firebase/storage";
import upload from '../assets/upload.png'

import { getDatabase, ref as databaseRef, set, push,onValue } from "firebase/database";

const Essential = () => {
  const [images, setImages] = useState([null, null, null, null]);
  const [imageUrls, setImageUrls] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [category, setCategory] = useState("Men");
  const [subcategory, setSubcategory] = useState("TopWear");
  const [bestseller, setBestseller] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [fetchedImages, setFetchedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage();
  const database = getDatabase(app);

  // Fetch stored product data (with images) from Firebase database
  useEffect(() => {
    const fetchProductData = async () => {
      const productsRef = databaseRef(database, 'Essentials');
      onValue(productsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const fetchedImages = Object.values(data).flatMap(product => product.images || []);
          setFetchedImages(fetchedImages);
        }
      });
    };

    fetchProductData();
  }, [database]);

  // Upload images and get URLs
  const uploadImage = async (productId) => {
    const urls = await Promise.all(
      images.map(async (file, index) => {
        if (file) {
          const fileName = `${productId}_${index}.jpeg`;
          const storageRef = ref(storage, `Essential/${fileName}`);
          const metaData = { contentType: 'image/jpeg' };

          await uploadBytes(storageRef, file, metaData);
          const url = await getDownloadURL(storageRef);
          return url;
        }
        return null;
      })
    );
    return urls.filter((url) => url !== null);
  };

  // Submit product data to Firebase
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const productRef = push(databaseRef(database, 'Essentials'));
      const productId = productRef.key;
      const urls = await uploadImage(productId);
      setImageUrls(urls);

      const productData = {
        id: productId,
        name,
        description,
        price,
        category,
        subcategory,
        bestseller,
        sizes,
        stock,
        colors,
        images: urls,
      };

      await set(productRef, productData);

      alert("Product data added successfully");

      // Clear form after submission
      setName("");
      setDescription("");
      setPrice("");
      setCategory("Men");
      setSubcategory("TopWear");
      setBestseller(false);
      setSizes([]);
      setStock("");
      setImages([null, null, null, null]);
      setImageUrls([]);
    } catch (error) {
      alert("Data submission error: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle size selection
  const toggleSize = (size) => {
    setSizes((prev) =>
      prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
    );
  };

  // Toggle color selection
  const toggleColor = (color) => {
    setColors((prev) =>
      prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
    );
  };
  return (
    <div className="flex">
    <form onSubmit={onSubmitHandler} className='flex flex-col w-full items-start gap-3'>
      <div>
        <p className='mb-2'>Upload Images</p>
        <div className='flex gap-3'>
          {images.map((img, index) => (
            <label key={index} htmlFor={`image${index}`}>
              <img 
                className='w-14' 
                src={!img ? upload : URL.createObjectURL(img)} 
                alt={`image${index + 1}`} 
              />
              <input 
                type="file" 
                id={`image${index}`} 
                hidden 
                onChange={(e) => {
                  const file = e.target.files[0];
                  setImages((prev) => {
                    const updatedImages = [...prev];
                    updatedImages[index] = file;
                    return updatedImages;
                  });
                }}
              />
            </label>
          ))}
        </div>
      </div>

      <div className='w-full'>
        <p className='mb-2'>Product Name</p>
        <input 
          onChange={(e) => setName(e.target.value)} 
          value={name} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          type="text" 
          placeholder='Type here' 
          required 
        />
      </div>

      <div className='w-full'>
        <p className='mb-2'>Product Description</p>
        <textarea 
          onChange={(e) => setDescription(e.target.value)} 
          value={description} 
          className='w-full max-w-[500px] px-3 py-2 border' 
          placeholder='Write here' 
          required 
        />
      </div>

      <div className='flex flex-col sm:flex-row gap-2 w-full sm:gap-8'>
        <div>
          <p className='mb-2'>Product Category</p>
          <select 
            onChange={(e) => setCategory(e.target.value)} 
            className='w-full px-3 py-2 border'
          >
            <option value="Men">Men</option>
            <option value="Women">Women</option>
            <option value="Kids">Kids</option>
          </select>
        </div>

        <div>
          <p className='mb-2'>Sub Category</p>
          <select 
            onChange={(e) => setSubcategory(e.target.value)} 
            className='w-full px-3 py-2 border'
          >
            <option value="TopWear">TopWear</option>
            <option value="BottomWear">BottomWear</option>
            <option value="WinterWear">WinterWear</option>
          </select>
        </div>

        <div>
          <p className='mb-2'>Product Price</p>
          <input 
            onChange={(e) => setPrice(e.target.value)} 
            value={price} 
            className='w-full px-3 py-2 sm:w-[120px] border' 
            type="number" 
            placeholder='25' 
            
            required 
          />
        </div>
        <div>
          <p className='mb-2'>Stock Available</p>
          <input 
            onChange={(e) => setStock(e.target.value)} 
            value={stock} 
            className='w-full px-3 py-2 sm:w-[120px] border' 
            type="number" 
            placeholder='10' 
            required 
          />
        </div>
      </div>
      <div>
        <p className='mb-2'>Colors</p>
        <div className='flex gap-3'>
          {["Jet Black", "Midnight Blue", "CosmicWhite"].map((color) => (
            <div key={color} onClick={() => toggleColor(color)}>
              <p className={`${colors.includes(color) ? "bg-pink-200" : "bg-slate-200"} cursor-pointer px-3 py-1`}>
                {color}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <p className='mb-2'>Product Sizes</p>
        <div className='flex gap-3'>
          {["S", "M", "L", "XL", "XXL"].map((size) => (
            <div key={size} onClick={() => toggleSize(size)}>
              <p className={`${sizes.includes(size) ? "bg-pink-200" : "bg-slate-200"} cursor-pointer px-3 py-1`}>
                {size}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className='flex gap-2 mt-2'>
        <input 
          type="checkbox" 
          id='bestseller' 
          checked={bestseller} 
          onChange={() => setBestseller((prev) => !prev)} 
        />
        <label className='cursor-pointer' htmlFor="bestseller">Add to Bestseller</label>
      </div>

      <button type='submit' className='w-28 py-3 mt-4 bg-black text-white' disabled={isLoading}>
        {isLoading ? 'Uploading...' : 'Add'}
      </button>
    </form>

    <div className="w-1/2 flex flex-col items-center gap-4">
        <h2 className='text-xl font-semibold'>Existing Product Images</h2>
        <div className='grid grid-cols-2 gap-4'>
          {fetchedImages.map((url, index) => (
            <img key={index} src={url} alt={`Product Image ${index + 1}`} className='w-24 h-24 object-cover' />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Essential;
